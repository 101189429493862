import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AppPrivacyPolicyComponent} from './app-privacy-policy/app-privacy-policy.component';
import {HomeComponent} from './home/home.component';


const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'app_privacy_policy', component: AppPrivacyPolicyComponent},
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
